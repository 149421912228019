
import Vue from 'vue';

import { ILikesConfig } from '@/types';

import GenerationTemplate from '@/components/modals/GenerationTemplate.vue';

export default Vue.extend({
    name: 'LikesManager',

    components: { GenerationTemplate },

    data() {
        return {
            likeConfigData: {
                min_likes: 9,
                max_likes: 19,
                is_active: false,
                days: 3,
            } as ILikesConfig,

            LikesManagerDisable: {
                managerType: 'like',
                id: 1,
                title: 'Еще не активированные мечты',
                description: `
                    Будет произведена модификация 
                    лайков всех мечт, попадающих в общий каталог, 
                    но не активированных, в указанном вами диапазоне 
                    за указанное количество дней.
                `,
                range: 'Диапазон модификации',
                modifyTime: 'Время на модификацию',
                isRunning: 'Генерация лайков запущена',
                modifySettings: 'Модифицировать лайки',
            },

            LikesManagerEnable: {
                managerType: 'like',
                id: 2,
                title: 'Активированные мечты',
                description: `
                    Будет произведена модификация 
                    лайков всех активированных мечт за 
                    указанное количество дней.
                `,
                range: 'Диапазон модификации',
                modifyTime: 'Время на модификацию',
                isRunning: 'Генерация лайков запущена',
                modifySettings: 'Модифицировать лайки',
            },
        };
    },
});
